<template>
	<div>
		<headerTop></headerTop>
		<!-- 主体 -->
		<div class="Order_content wrap">
			<div class="header">
				<div @click="GoIndex()" class="fl">
					<img src="../../assets/image/funuo.png" alt />
					孚诺大药房
				</div>
				<div class="fr">
					<el-steps :active="state" align-center>
						<el-step title="选择商品" description></el-step>
						<el-step title="核对订单信息" description></el-step>
						<el-step title="提交订单" description></el-step>
					</el-steps>
				</div>
			</div>
			<div class="content">
				<div class="topTitle">填写核对订单信息<span class="shopName">售卖药店: <span>{{shop_name}}</span></span></div>

				<div class="content_c">
					<div class="titles titless">
						收货地址
						<div class="fr" @click="newadderss">新建收货地址</div>
					</div>
					<div v-if="addresList2.length==0" style="margin:15px 35px;">请添加收货地址</div>
					<div class="adderbox" ref="adderbox" v-else>
						<div @click="addresTab(1,v,i)" v-for="(v,i) in addresList2" :key="i" class="addres_list">
							<div :class="{active1:typeactive == v.add_time+i}" @clcik.shop="posttype=1" class="fl ffl">快递</div>
							<span class="fl a_l_d">
								{{v.user_name}} {{v.province_name}}-{{v.city_name}}-{{v.area_name}}-{{v.address}} {{v.mobile}}
							</span>
							<div class="default" v-if="v.is_default==1">默认地址</div>
							<div class="right_btn">
								<span v-if="v.is_default==0" @click.stop="setadder(v.id)">设置默认地址</span>
								<span class="edit" @click.stop="editadder(v)">编辑</span>
								<span v-if="v.is_default==0" @click="DeleteAdder(v.id)">删除</span>
							</div>
						</div>
						<div class="openadderlist" @click="openadderlists">
							<span class="el-icon-d-arrow-left" ref="jiantou"></span>
							{{opentext}}
						</div>
					</div>

					<div @click="addresTab(2,v,i)" v-for="(v,i) in shopList" :key="i" v-if="shop_id==v.id" class="addres_list addres_ziti">
						<div :class="{active1:addresType ==2}" class="fl ffl" v-if="shop_id==v.id">自提</div>
						<div class="fl a_l_d" v-if="shop_id==v.id">
							{{v.shop_name}}
							<i></i>
							{{v.address}}
							<i></i>
							{{v.mobile}}
						</div>
					</div>
					<div class="pay_type">
						<div class="titles">支付方式</div>
						<span v-for="(i,index) in paymethod" :class="{paymentActive:paytype==index}" @click="choicePaymethod(i,index)">{{i.title}}</span>
						<div class="Offlinebox" v-if="paytype==4">
							<p class="title">线下支付放肆</p>
							<p>收款单位: {{offlineinfor.payee}}</p>
							<p>开户行: {{offlineinfor.bank}}</p>
							<p>银行账号: {{offlineinfor.bank_account}}</p>
						</div>
					</div>
					<div class="goodsList">
						<div class="titles">商品清单</div>
						<div v-for="(v,j) in arr.goodsDatas " :key="j" class="goodsList_box">
							<div class="shopbox">
								<img class="fl" :src="v.goods_img" alt />
								<div class="g_b_details fl red">
									<p class="two">{{v.goods_name}}</p>
									<p class="one">
										<div style="margin-bottom: 10px;" v-if="v.dzdesc!=''"><span>满折</span>{{v.dzdesc}}。</div>
										<div style="margin-bottom: 10px;" v-if="v.mzdesc!=''"><span>买赠</span>{{v.mzdesc}}。</div>
									</p>
								</div>
								<div class="goods_sum fr">
									<span class="span2">
										<span class="span3">X</span>
										{{v.num}}
									</span>
									<span class="span1">￥{{v.price}}</span>
								</div>
							</div>
							<div class="discount  shopcoupon">
								<div class="shopCouponTitle" v-if="v.coupon_list.length!=0">商品优惠券</div>
								<div class="discount_d">
									<div v-for="(value, i) in v.coupon_list" :key="i" class="d_d_d" @click="shopselectCoupon(v,value)">
										<div class="select" v-if="value.isactive"><img src="../../assets/image/rightleft.png" alt=""></div>
										<div class="fl">
											￥
											<span>{{ parseInt(value.money) }}</span>
											<br />优惠券
										</div>
										<div class="fr">
											<p class="p1 one">{{ value.title }}</p>
											<p class="p2 one">满{{parseInt(value.limit)}}元时可使用</p>
											<p class="p3 one">{{ value.start_time}} 至 {{ value.end_time }}期间可用</p>
										</div>
									</div>
									<!-- 	<div class="none_coupon" v-if="arr.coupon_list.length == 0">该商品暂无优惠券</div> -->
								</div>
							</div>
						</div>
					</div>
					<div class="invoice pay_type">
						<div class="titles">发票信息</div>
						<span :class="{active1:invoiceType==0}" @click="getinvoice(0)">不要发票</span>
						<span :class="{active1:invoiceType==1}" @click="getinvoice(1)">开发票</span>
						<div class="invoiceoption" v-if="invoiceType==1">
							<div class="title">
								<span>发票类型：</span>
								<span>
									<el-radio v-model="invoice_mold" label="1">电子发票</el-radio>
								</span>
								<span>
									<el-radio v-model="invoice_mold" label="2">普通发票</el-radio>
								</span>
							</div>
							<div class="invoicelist">
								<div class="invoiceinfor">
									<span class="type">{{invoicelist.type==0?"个人":"公司"}}</span>
									<span class="name">{{invoicelist.title}}</span>
									<span class="tax">{{invoicelist.tax_no}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="discount shopcoupon">
						<div class="titles">平台优惠券/积分</div>
						<div class="discount_d">
							<div v-for="(v, i) in arr.coupon_list" :key="i" class="d_d_d" @click="selectcoupon(v,i)">
								<div class="select" v-show="ptselectindex==i"><img src="../../assets/image/rightleft.png" alt=""></div>
								<div class="fl">
									￥
									<span>{{ parseInt(v.money) }}</span>
									<br />优惠券
								</div>
								<div class="fr">
									<p class="p1 one">{{ v.title }}</p>
									<p class="p2 one">满{{parseInt(v.limit)}}元时可使用</p>
									<p class="p3 one">{{ v.start_time }} 至 {{ v.end_time }}期间可用</p>
								</div>
							</div>
							<!-- <div class="none_coupon" v-if="arr.coupon_list.length == 0">该商品暂无优惠券</div> -->
						</div>
						<div class="discount_b">
							<el-checkbox v-model="checked" @change="getzekou">可用积分{{arr.score}}</el-checkbox>
							<div class="fr">
								金额总抵用
								<span>￥{{arr.money_score}}</span>
							</div>
						</div>
					</div>
				</div>
				<div class="b_bottom">
					<div class="fl">
						购药提示
						<br />1.药品为特殊商品，非质量问题不退货。
						<br />2.医疗医药经营类企事业单位不能从医药零售企业购买药品。
					</div>
					<div class="fr txt1">
						￥ {{arr.goodsMoneySum}}
						<!-- 总金额 -->
						<br />
						￥{{arr.postage}}
						<!-- 运费 -->
						<br />
						￥ {{arr.money_youhui}}
						<!-- 优惠金额 -->
					</div>
					<div class="fr txt2">
						<span v-if="arr!= ''">{{arr.goodsDatas.length}}</span> 件商品，总金额
						<br />运费
						<br />折扣金额
					</div>
				</div>
				<div class="money">
					<p class="p1">
						应付金额
						<span>￥{{arr.money}}</span>
					</p>
					<p v-if="addresTypes == 1" class="p2">
						寄送至： {{addresList1.province_name}}-{{addresList1.city_name}}-{{addresList1.area_name}}-{{addresList1.address}}
						收货人：{{addresList1.user_name}} {{addresList1.mobile}}
					</p>
					<p v-if="addresTypes == 2" class="p2">
						自提 ：{{addresList1.shop_name}}-{{addresList1.address}}
						联系电话 ： {{addresList1.mobile}}
					</p>
				</div>
			</div>
			<div class="send">
				<button @click="sendChecking()">提交订单</button>
			</div>
			<div class="newAddress" v-if="show" @mousewheel.prevent>
				<div class="content">
					<div class="title">
						<span class="fl">收货地址</span>
						<img class="fr" @click="show = false" src="@/assets/image/Popup_Close_icon@2x.png" alt />
					</div>
					<div class="item item1">
						<span>所在地区</span>
						<el-cascader @change="filterAddress" :props="props"></el-cascader>
					</div>
					<div class="item">
						<span>收货人</span>
						<input v-model="name" placeholder="请输入收货人" />
					</div>
					<div class="item">
						<span>联系电话</span>
						<input type="number" v-model="phone" placeholder="请输入联系电话" />
					</div>
					<div class="item">
						<span>详细地址</span>
						<textarea type="textarea" v-model="address" placeholder="请输入详细地址"></textarea>
					</div>
					<button class="button" v-if="bianji==0" @click="savaAddress">保存</button>
					<button class="button" v-if="bianji==1" @click="edit">保存</button>
				</div>
			</div>
		</div>
		<div class="bg1">
			<publicBottom></publicBottom>
		</div>
	</div>
</template>
<script>
	import headerTop from "@/components/public/public_headerTop.vue";
	import publicBottom from "@/components/public/public_bottom";

	import {
		confirmCartOrder
	} from "@/request/index"; //// 商品直接下单 进入确认订单页面
	import {
		get_shop_list
	} from "@/request/public"; // 商铺列表
	import {
		user_address_List
	} from "@/request/user"; //地址列表
	import {
		submitCartOrder
	} from "@/request/index"; // 提交订单
	import {
		orderCoupon
	} from "@/request/index"; // 确认订单页获取通用优惠券
	import {
		getUserScore
	} from "@/request/index"; // 获取用户积分
	import {
		getAreaLista
	} from "@/request/user";
	import {
		orderGoodsCoupon
	} from "@/request/index"; // 获取商品优惠券
	import {
		user_address_Add
	} from "@/request/user";

	export default {
		components: {
			headerTop,
			publicBottom
		},
		data() {
			return {
				checked: false,
				typeactive: "",
				infor: "",
				state: 2, // 步骤条
				addresType: -1, // 快递 自提
				addresTypes: 1, //1  快递  2自提
				payType: "支付宝", // 支付 方式
				invoiceType: "", //发票 方式
				couponType: 0, // 优惠券
				integral: 0, // 积分
				goods_id: "", //商品 ID
				shop_id: "", // 店铺 ID
				arr: "", // 主体信息
				addresList: "", // 地址 列表
				addresAll: 0, //显示全部
				addresList1: [], // 地址 列表
				addresList2: [], // 地址 列表
				shopList: [], // 药店 列表
				goodsList: [], // 商品 列表
				couponidList: [], //优惠券 集合
				lng: "",
				lat: "",
				props: {},
				options: "",
				name: "",
				phone: "",
				address: "",
				show: false,
				shop_name: "",
				bianji: 0,
				adderid: "",
				opentext: "展开",
				paymethod: [{
					title: "支付宝",
					type: "alipayWeb"
				}, {
					title: "微信支付",
					type: "wechatScan"
				}, {
					title: "银行卡支付",
					type: "bankpay"
				}, {
					title: "余额支付",
					type: "balance"
				}, {
					title: "线下支付",
					type: "offline"
				}],
				paytype: "", //支付方式切换
				invoice_mold: "0",
				invoicelist: "",
				offlineinfor: "",
				invoiceid: "",
				posttype: 0,
				shopselectindex: -1,
				ptselectindex: -1,
				ptcouponid: "",
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			this.infor = JSON.parse(this.$route.query.infor); //商品 ID
			console.log(this.infor);
			this.goods_id = this.infor.good_list;
			this.shop_id = this.infor.shop_id; // 店铺 ID
			this.lng = localStorage.getItem("lzyyShopLng"); //
			this.lat = localStorage.getItem("lzyyShopLat"); //
			this.start();
			this.createProps();
		},
		methods: {
			shopselectCoupon(v, value) {
				if (value.isactive == true) {
					value.isactive = false;
					v.usecoupon=false;
				} else {
					for (let item of v.coupon_list) {
						item.isactive = false;
					}
					value.isactive = true;
					v.usecoupon=true;
				}
				
				for (let i = 0; i < this.arr.goodsDatas.length; i++) {
					if (this.arr.goodsDatas[i].usecoupon == true) {
						for (let j = 0; j < this.arr.goodsDatas[i].coupon_list.length; j++) {
							if (this.arr.goodsDatas[i].coupon_list[j].isactive == true) {
								this.couponidList[i] = this.arr.goodsDatas[i].coupon_list[j].id;
							}

						}

					} else {
						this.couponidList[i] = 0;
					}

				}
				if(this.ptcouponid!=""&&this.couponidList.length<this.arr.goodsDatas.length+1){
					this.couponidList.push(this.ptcouponid);
				}
				this.getzekou();
				this.$forceUpdate();
			},
			selectcoupon(v, i) {
				if(this.couponidList.length>this.arr.goodsDatas.length){
					console.log(this.couponidList);
						this.couponidList.pop();
				}
				if(this.ptselectindex==i){
					this.ptselectindex=-1;
					this.ptcouponid="";
					
				}else{
					this.ptselectindex=i;
					this.ptcouponid=v.id;
					this.couponidList.push(this.ptcouponid);
				}
				this.getzekou();
			},
			// 获取折扣
			getzekou() {
				console.log(this.couponidList);
				confirmCartOrder({
					token: this.$token,
					id: this.goods_id,
					couponids: this.couponidList,
					shop_id: this.shop_id,
					posttype: this.addresType,
					usescore: this.checked ? 1 : 0,
				}).then(res => {
					if(res.code==1){
						this.arr.money_youhui = res.data.money_youhui;
						this.arr.money = res.data.money;
						
					}else{
						this.$message.error(res.msg);
					}
				});
			},
			// 获取发票人员列表
			getinvoice(value) {
				this.invoiceType = value;
				if (value == 1) {
					this.$axios.post("/api/user_invoice/getinvoice", {
						token: this.$token
					}).then(res => {
						if (res.data.code == 1) {
							this.invoicelist = res.data.data;
							this.invoiceid = this.invoicelist.id;
						}
					});
				} else {
					this.invoiceid = "";
				}
			},
			// 选择支付方式
			choicePaymethod(i, index) {
				this.paytype = index;
				this.payType = i.type;
				if (index == 4) {
					this.$axios.post("/api/public/getSysconf", {
						token: this.token
					}).then(res => {
						console.log(res.data);
						if (res.data.code == 1) {
							this.offlineinfor = res.data.data;
						}
					});
				}
			},
			// 展开
			openadderlists() {
				if (this.opentext == "展开") {
					this.$refs.adderbox.style.height = this.addresList2.length * 93 + "px";
					this.$refs.jiantou.style.transform = "rotate(" + 90 + "deg)";
					this.opentext = "收起";
				} else {
					this.$refs.adderbox.style.height = 93 + "px";
					this.$refs.jiantou.style.transform = "rotate(" + -90 + "deg)";
					this.opentext = "展开";
				}
			},
			// 删除
			DeleteAdder(id) {
				this.$axios.post("/api/user_address/del", {
					id: id,
					token: this.$token
				}).then(res => {
					if (res.data.code == 1) {
						this.$message.success("删除成功");
						this.getUserAddressList();
					}
				})
			},
			newadderss() {
				this.show = true;
				this.bianji = 0;
			},
			// 编辑地址
			editadder(v) {
				console.log(v);
				this.bianji = 1;
				this.show = true;
				this.name = v.user_name;
				this.phone = v.mobile;
				this.address = v.address;
				this.adderid = v.id;
			},
			// 编辑保存
			edit(v) {
				if (!this.option) {
					this.$message.error("请输入收货地址");
				} else if (!this.phone) {
					this.$message.error("请输入电话号");
				} else if (!this.name) {
					this.$message.error("请输入收货人姓名");
				} else if (!this.address) {
					this.$message.error("请输入详细地址");
				}
				this.$axios.post("/api/user_address/edit", {
					id: this.adderid,
					token: this.$token,
					user_name: this.name,
					mobile: this.phone,
					area_id: this.option[2],
					address: this.address
				}).then(res => {
					if (res.data.code == 1) {
						this.$message.success("修改成功");
						this.show = false;
					}
				})
			},
			// 设置默认地址
			setadder(id) {
				this.$axios.post("/api/user_address/setDefault", {
					token: this.$token,
					id: id
				}).then(res => {
					console.log(res);
					this.getUserAddressList();
				});
			},
			createProps() {
				let id = 0;
				this.props = {
					lazy: true,
					lazyLoad(node, resolve) {
						getAreaLista({
							token: this.$token,
							pid: node.value
						}).then(res => {
							if (res.code == 1) {
								let result = [];
								for (var i = 0; i < res.data.length; i++) {
									result.push({
										value: res.data[i].id,
										label: res.data[i].name,
										id: res.data[i].id,
										leaf: node.level == 2
									});
								}
								resolve(result);
							}
						});
					}
				};
			},
			sendChecking() {
				if (this.addresType == -1) {
					this.$message.error("请选择配送方式");
					return;
				}
				
				submitCartOrder({
					token: this.$tokens,
					id: this.goods_id, //商品id
					shop_id: this.shop_id, //店铺ID
					couponids: this.couponidList, //优惠券 集合
					posttype: this.addresType, // 配送方式
					usescore: this.checked == true ? 1 : 0, //使用积分
					invoice_id: this.invoiceid, // 是否使用发票
					invoice_mold: this.invoice_mold,
					addressid: this.adderid
				}).then(res => {
					console.log(res);
					if (res.code == 1) {
						let data = res.data;
						if (this.payType == "offline") {
							this.$router.replace({
								name: "支付结果",
								query: {
									state: 3,
									num: data.out_trade_no
								}
							});
						} else {
							if(this.payType=="balance"){
								this.$axios.post("api/user/getInfo",{token:this.$tokens}).then(res=>{
									if(res.data.code==1){
										  if(res.data.data.is_set_paypass==0){
											this.$message.error("您还未设置支付密码,请前往个人资料设置支付密码");
											return false;
										}else{
											this.$router.replace({
												path: `./pay?money=${data.money}&out_trade_no=${data.out_trade_no}&pay_type=${this.payType}`
											});
										}
									}
								})
							}else{
								this.$router.replace({
									path: `./pay?money=${data.money}&out_trade_no=${data.out_trade_no}&pay_type=${this.payType}`
								});
							}
							
						}
					} else {
						this.$message.error(res.msg);
					}
				});
				console.log(couponidList);
			},
			//  快递 还是 自提
			addresTab(e, v, i) {
				this.addresList1 = v;
				this.addresType = e;
				this.typeactive = v.add_time + i;
				console.log(v);
				if (e == 1) {
					this.adderid = v.id;
				} else {
					this.adderid = "";
				}
				console.log(v);
				confirmCartOrder({
					token: this.$token,
					id: this.goods_id,
					shop_id: this.shop_id,
					posttype: this.addresType,
					usescore: this.checked ? 1 : 0,
				}).then(res => {
					this.arr = res.data;
				});
			},
			GoIndex() {
				this.$router.replace({
					path: "/"
				});
			},
			start() {
				// 获取订单相关信息
				confirmCartOrder({
					token: this.$token,
					id: this.goods_id,
					shop_id: this.shop_id
				}).then(res => {
					this.arr = res.data;

					for (let i = 0; i < this.arr.goodsDatas.length; i++) {
						this.arr.goodsDatas[i].usecoupon = false;
						for (let j = 0; j < this.arr.goodsDatas[i].coupon_list.length; j++) {
							this.arr.goodsDatas[i].coupon_list[j].isactive = false;
						}
					}
					console.log(this.arr);
					this.getOrderCoupon();
				});
				//    药店
				get_shop_list({
					lng: this.lng,
					lat: this.lat
				}).then(ress => {
					if (ress.data.data != "") {
						this.shopList = ress.data.data;
						for (let i of this.shopList) {
							if (i.id == this.shop_id) {
								this.shop_name = i.shop_name;
							}
						}
						console.log(this.shopList);
					}
				});
				//   获取用户积分
				getUserScore({
					token: this.$token
				}).then(res => {
					this.integral = res.data.score;
				});
				//   地址列表
				this.getUserAddressList();
			},
			getOrderCoupon() {
				// 获取用户领取的商品优惠券
				orderCoupon({
					token: this.$token,
					shop_id: this.shop_id,
					money: parseFloat(this.arr.money)
				}).then(res => {
					console.log(res);
				});
			},
			moreAddressList() {
				this.addresAll = 1;
				this.getUserAddressList();
			},
			getUserAddressList() {
				user_address_List({
					token: this.$token
				}).then(res => {
					let data = res.data.data;
					let isdefault = "";
					// 把默认的地址放在第一位
					for (let i in data) {
						if (data[i].is_default == 1) {
							isdefault = data.splice(i, 1);
							console.log(isdefault);
						}
					}
					isdefault.push(...data)
					this.addresList2 = isdefault;
					this.addresList1 = this.addresList2[0];
					this.addresType = 1;
					this.typeactive = this.addresList2[0].add_time + 0;
					this.adderid = this.addresList2[0].id;
				});
			},
			filterAddress(res) {
				this.option = res;
			},
			savaAddress() {
				if (!this.option) {
					this.$message.error("请输入收货地址");
				} else if (!this.phone) {
					this.$message.error("请输入电话号");
				} else if (!this.name) {
					this.$message.error("请输入收货人姓名");
				} else if (!this.address) {
					this.$message.error("请输入详细地址");
				}
				user_address_Add({
					token: this.$token,
					user_name: this.name,
					mobile: this.phone,
					area_id: this.option[2],
					address: this.address
				}).then(res => {
					if (res.code == 1) {
						this.$message.success("添加成功!");
						this.getUserAddressList();
						this.show = false;
					} else {
						console.log(res);
						this.$message.error(res.msg);
					}
				});
			}
		}
	};
</script>
<style lang="less" scoped>
	.Offlinebox {
		position: absolute;
		left: 700px;
		top: 10px;

		p {
			color: #CCCCCC;
			margin-bottom: 5px;
		}

		.title {
			color: #f7941e;
		}
	}

	/deep/.el-radio__input.is-checked .el-radio__inner {
		background-color: #4EEEC8 !important;
		border: #4EEEC8;
	}

	/deep/.el-radio__input.is-checked+.el-radio__label {
		color: #4EEEC8;
	}

	.paymentActive {
		border-color: #4EEEC8 !important;
		color: #4EEEC8;
	}

	.adderbox {
		height: 93px;
		overflow: hidden;
		position: relative;
		transition: all 0.2s linear;

		.openadderlist {
			position: absolute;
			bottom: 0;
			left: 170px;
			font-size: 14px;
			cursor: pointer;

			span {
				transition: all 0.2s linear;
				transform: rotate(-90deg);
			}
		}
	}

	.default {
		background-color: #ccc;
		color: #fff;
		font-size: 12px;
		display: inline-block;
		padding: 2px;
		margin-top: 7px;
		margin-left: 10px;
	}

	.right_btn {
		float: right;
		display: flex;
		justify-content: flex-end;
		width: 300px;
		cursor: pointer;

		span {
			display: inline-block;
			margin-right: 30px;
		}

		span:hover {
			color: red;
		}
	}

	//   商品 订单 详情
	.Order_content {
		margin-top: 36px;

		.header {
			height: 108px;
			margin-top: 66px;

			.fl {
				font-size: 30px;
				padding-top: 33px;
				font-weight: 500;
				color: rgba(0, 0, 0, 1);
			}

			img {
				width: 56px;
				height: 36px;
			}

			.fr {
				width: 540px;
				padding-top: 30px;
			}
		}

		.content {}

		.topTitle {
			height: 40px;
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: rgba(51, 51, 51, 1);
			line-height: 40px;
			padding-left: 30px;
		}

		.content_c {
			border: 1px solid rgba(230, 230, 230, 1);
			padding-left: 30px;
			padding-right: 30px;
			padding-bottom: 40px;
		}

		.titles {
			height: 40px;
			font-size: 16px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: rgba(51, 51, 51, 1);
			line-height: 40px;
			margin-top: 20px;
			margin-bottom: 30px;
		}

		.titless {
			margin-bottom: 0;
		}

		.addres_list:hover {
			background-color: #E5E5E5;
		}

		.addres_list {
			padding-top: 30px;
			overflow: hidden;
			padding-left: 34px;
			font-size: 16px;
			color: rgba(51, 51, 51, 1);
			border-bottom: 1px solid #f2f2f2;
			padding-bottom: 30px;

			// padding-top: 30px;
			.ffl {
				width: 100px;
				height: 32px;
				line-height: 28px;
				text-align: center;
				// background  : rgba(230, 255, 249, 1);
				border: 2px solid #cccccc;
				margin-right: 36px;
				cursor: pointer;
			}

			.a_l_d {
				padding-top: 6px;
				width: 400px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				span {
					font-size: 12px;
					color: white;
					text-align: center;
					display: inline-block;
					width: 65px;
					height: 22px;
					margin-left: 20px;
					background: rgba(204, 204, 204, 1);
				}

				p {
					margin-top: 26px;
					color: #dcdcdc;
				}
			}
		}

		.addres_ziti {
			padding-top: 30px;
		}

		.pay_type {
			position: relative;

			>span {
				display: inline-block;
				width: 100px;
				height: 32px;
				line-height: 32px;
				text-align: center;
				// background  : rgba(230, 255, 249, 1);
				border: 2px solid #cccccc;
				margin-right: 36px;
				cursor: pointer;
			}

			.invoiceoption {
				margin-left: 4px;
				margin-top: 10px;

				.title {
					font-size: 14px;

					span {
						margin-right: 10px;
					}
				}

				.invoicelist {
					margin-top: 10px;

					.invoiceinfor {
						span {
							margin-right: 20px;
							font-size: 14px;
						}

						.type {
							border: 2px solid #4EEEC8;
							padding: 2px 10px;
							color: #4EEEC8;
						}
					}

				}
			}
		}

		.goodsList {}

		.goodsList_box {
			// height: 140px;
			background: rgba(252, 252, 252, 1);
			padding-left: 50px;
			padding-top: 20px;
			padding-right: 50px;

			.shopbox {
				height: 120px;
			}

			.shopcoupon {
				.discount_d {
					margin: 0;
					padding-left: 0;
					border: none;
					display: flex;
					justify-content: start;
					// background-color:red;
				}
			}

			.fl {
				display: inline-block;
				width: 100px;
				height: 100px;
				background: rgba(255, 255, 255, 1);
				border: 1px solid rgba(230, 230, 230, 1);
				// vertical-align: middle;
			}

			.g_b_details {
				display: inline-block;
				margin-left: 20px;
				height: 100px;
				width: 700px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(102, 102, 102, 1);
				padding-top: 12px;
				border: none;

				.two {
					height: 28px;
					line-height: 18px;
					margin-bottom: 10px;
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: rgba(51, 51, 51, 1);
				}

				span {

					display: inline-block;
					text-align: center;
					width: 38px;
					height: 20px;
					margin-right: 12px;
					background: rgba(253, 182, 90, 1);
				}

				.one {}
			}

			.goods_sum {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(51, 51, 51, 1);

				.span2 {
					margin-right: 20px;
				}

				.span3 {
					font-size: 12px;
				}

				.span1 {
					font-size: 20px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: rgba(241, 25, 25, 1);
				}
			}
		}

		.discount {
			margin-top: 30px;
			.shopCouponTitle{
				position: relative;
				left: -50px;
				// background-color:red;
			}
			.shopcoupon {
			
				.discount_d {
					margin: 0;
					padding-left: 0;
					border: none;
				}
			}

			.discount_d {
				padding-top: 20px;
				margin-top: 10px;
				border: 1px solid #f2f2f2;
				border-bottom: none;
				display: flex;
				flex-wrap: wrap;
				padding-left: 26px;

				.none_coupon {
					margin-bottom: 20px;
				}
			}

			.titles {
				margin: 0;
			}

			.d_d_d {
				width: 314px;
				height: 80px;
				background: rgba(230, 255, 249, 1);
				border-radius: 10px;
				overflow: hidden;
				margin-bottom: 20px;
				margin-right: 26px;
				position: relative;

				.select {
					position: absolute;
					right: 0;
					top: 0;
				}

				.fl {
					width: 97px;
					height: 80px;
					text-align: center;
					padding-top: 10px;
					color: white;
					font-size: 16px;
					background: rgba(69, 239, 197, 1);
				}

				span {
					font-size: 30px;
				}

				.fr {
					float: left;
					width: 200px;
					height: 80px;
					padding-left: 12px;
					padding-top: 8px;
				}

				.p1 {
					font-size: 17px;
					font-family: PingFang SC;
					font-weight: 500;
					color: rgba(1, 1, 1, 1);
					margin-bottom: 6px;
				}

				.p2 {
					font-size: 15px;
					font-family: PingFang SC;
					font-weight: 500;
					color: rgba(102, 102, 102, 1);
					line-height: 19px;
					margin-bottom: 2px;
				}

				.p3 {
					font-size: 12px;
					font-family: PingFang SC;
					font-weight: 500;
					color: rgba(102, 102, 102, 1);
					line-height: 19px;
				}
			}
		}

		.discount_b {
			height: 36px;
			line-height: 36px;
			background: rgba(252, 252, 252, 1);
			border: 1px solid rgba(242, 242, 242, 1);
			font-size: 14px;
			color: rgba(0, 0, 0, 1);
			position: relative;
			padding-left: 20px;

			>span {
				position: absolute;
				left: 30px;
				top: 8px;
				display: inline-block;
				width: 16px;
				height: 16px;
				border: 1px solid rgba(102, 102, 102, 1);
				border-radius: 50%;
			}

			.fr {
				margin-right: 30px;

				span {
					font-size: 16px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: rgba(240, 0, 0, 1);
				}
			}
		}

		.b_bottom {
			margin-top: 40px;

			overflow: hidden;

			.fl {
				font-size: 15px;
				font-family: PingFang SC;
				font-weight: 500;
				color: rgba(240, 0, 0, 1);
				line-height: 26px;
				margin-left: 40px;
			}

			.fr {
				line-height: 30px;
				text-align: right;
			}

			.txt1 {
				min-width: 160px;

				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(0, 0, 0, 1);
			}

			.txt2 {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;

				span {
					color: rgba(243, 84, 84, 1);
				}
			}
		}

		.money {
			height: 72px;
			background: rgba(252, 252, 252, 1);
			padding-top: 8px;

			.p1 {
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(0, 0, 0, 1);
				text-align: right;

				span {
					display: inline-block;
					min-width: 156px;
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: rgba(240, 0, 0, 1);
				}
			}

			.p2 {
				margin-top: 6px;
				text-align: right;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(179, 179, 179, 1);
			}
		}

		.send {
			text-align: right;
			margin-top: 24px;
			margin-bottom: 88px;
		}

		button {
			font-size: 20px;
			width: 168px;
			height: 40px;
			background: rgba(240, 0, 0, 1);
			border-radius: 8px;
		}

		.active1 {
			border: 2px solid #38eec1 !important;
			background: #e6fff9;
			color: #4EEEC8;
		}

		.active2 {
			border: 2px solid #38eec1 !important;
			background: #38eec1;
		}
	}

	.newAddress {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0px;
		left: 0px;
		background: rgba(0, 0, 0, 0.5);
		z-index: 5;

		.content {
			width: 550px;
			height: 350px;
			background: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			margin-left: -275px;
			margin-top: -175px;

			.title {
				box-sizing: border-box;
				padding-top: 10px;
				height: 40px;
				background: #eee;

				span {
					margin-left: 10px;
				}

				img {
					width: 15px;
					height: 15px;
					margin-right: 10px;
					cursor: pointer;
				}
			}

			.item {
				height: 40px;
				width: 400px;
				margin: 8px 0px;
				margin-left: 20px;

				span {
					display: inline-block;
					width: 80px;
					text-align-last: justify;
					margin-right: 20px;
					height: 30px;
					line-height: 30px;
				}

				input {
					border: 1px solid #c0c4cc !important;
					height: 30px;
					line-height: 30px;
					width: 200px;
					border-radius: 5px;
					padding-left: 10px;
				}

				textarea {
					width: 250px;
					height: 80px;
					font-size: 16px;
					vertical-align: middle;
					border: 1px solid #c0c4cc !important;
					resize: none;
					padding: 8px;
					border-radius: 5px;
					color: #666;
				}
			}

			.button {
				position: absolute;
				padding: 5px 22px;
				border: 1px solid red;
				background: #fff;
				color: #f00000;
				bottom: 10px;
				left: 220px;
				border-radius: 5px;
				cursor: pointer;
				outline: none;
				width: 120px;
			}
		}
	}

	.shopName {
		display: inline-block;
		margin-left: 50px;

		span {
			color: #4EEEC8
		}
	}
</style>
